import React from 'react';
import { Link } from 'gatsby';

import Layout from '../layout/main';
import Image from '../components/images/image';

// SEO imports
import SEO from '../components/seo';
import ogImage from '../assets/images/og-images/adaptix.jpg';

import IntroBackground from '../components/images/introBackground';

import Fade from 'react-reveal/Fade';

const ThankYou = () => (
	<Layout>
		<SEO
			title="Thank You | Strong Mind Performance Consulting"
			description="Thanks for your interest in Strong Mind. Expect to hear from us soon!"
			image={ogImage}
		/>
		<section id="intro">
			<Fade>
				<IntroBackground />
			</Fade>
			<div className="intro__content">
				<div className="container">
					<Fade>
						<h1 className="shout">Thank you!</h1>
						<p className="talk">Thanks for your interest in Strong Mind. Expect to hear from us soon!</p>
						<Link className="btn btn__accent" to="/">
							Back to home
						</Link>
					</Fade>
				</div>
			</div>
		</section>
	</Layout>
);

export default ThankYou;
