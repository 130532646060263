import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const IntroBackground = () => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "introBG9.jpg" }) {
				childImageSharp {
					fluid(maxHeight: 2000) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return <Img alt="An athlete lacing up their shoes." fluid={data.placeholderImage.childImageSharp.fluid} />;
};

export default IntroBackground;
